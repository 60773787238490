<!-- <template>
  <main class="login">
    <section class="forms">
      <form class="register" @submit.prevent="register">
        <h2>Register</h2>

        <input 
          type="email" 
          placeholder="Email address"
          v-model="register_form.email" />

        <input 
          type="password" 
          placeholder="Password"
          v-model="register_form.password" />

          <input 
            type="submit"
            value="Register" />
      </form>

      <form class="login" @submit.prevent="login">
        <h2>Login</h2>

        <input 
          type="email" 
          placeholder="Email address"
          v-model="login_form.email" />

        <input 
          type="password" 
          placeholder="Password"
          v-model="login_form.password" />

          <input 
            type="submit"
            value="Login" />
      </form>
    </section>
  </main>
</template> -->
<template>
  <main class="login">
    <div class="container d-flex align-items-center justify-content-center z-2">
      <div class="row mobile login-screen g-0" style="background: white;">
        <!-- <div class="row mobile align-content-between g-0" style="background-image: linear-gradient(180deg, rgba(20, 20, 20, 0.0), rgba(20, 20, 20, 0.35)), url('/img/ui-theme/login-background.png'); background-size: cover;"> -->
        <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end">
          <!-- <router-link
                :to="{
                  name: componentAdmin
                }"
                class="btn-back opacity-0"
              >
              </router-link> -->
          <div class="btn" style="opacity: 0">
            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
          </div>
          <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight" @click="this.nextApp" v-if="this.loginSection == false">
            <img src="/img/ui-theme/menu-icon-black.svg" alt="Fullscreen">
          </button>
        </div>
        <div class="col-12 d-flex align-items-center z-2 login-logo-section">
          <div class="row g-0">
            <div class="col-12" style="text-align: center;">
              <img src="/img/ui-theme/ritz_logo.png" alt="Fullscreen" class="center-login-logo">
            </div>
          </div>
          <div class="row g-0">
            <div class="col-12" style="text-align: center;">
              <img src="/img/ui-theme/mbsm-balanced-logo-4.png" alt="Fullscreen" class="center-login-logo">
            </div>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center sticky-bottom">
          <!-- <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" style="height:35px"/> -->
        </div>
        <div class="col-12 d-flex align-items-center justify-content-between sticky-bottom footer-logo-section"
          style="position: fixed; bottom: 0;">
          <img src="/img/ui-theme/peligra.png" alt="Fullscreen"
            style="height: 3em; margin-bottom: 2em; margin-left: 3em;">
          <img src="/img/ui-theme/giannarelli_logo.png" alt="Fullscreen"
            style="height: 2.5em;  margin-bottom: 2em; margin-right: 3em;">
          <!-- <div style="width: 90%; padding: 20px 0px;">
                    <div class="developed-header">DEVELOPED BY</div>
                    <div class="d-flex justify-content-center">
                        <div class="developed-text">PELLIGRA GROUP</div>
                        <div class="developed-text" style="margin-left: 20px">GIANNARELLI GROUP</div>
                    </div>
                </div> -->
          <!-- <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" style="height:35px"/> -->
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center sticky-bottom"
          style="padding:10px; position: fixed; bottom: 0; text-align: right;" v-if="this.loginSection == true">
          <section class="forms w-100 align-items-end" style="max-width: 400px;">
            <form class="login" @submit.prevent="this.loginFunction">

              <input type="email" placeholder="Email address" v-model="login_form.email" class="form-control" />

              <input type="password" placeholder="Password" v-model="login_form.password" class="form-control  mt-2" />

              <input type="submit" value="Login" class="btn btn-primary mt-2"
                style="background-color: #5A55EE; border-color: #5A55EE;" />
            </form>
          </section>
          <!-- <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" style="height:35px"/> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import global from '../global'
import { ref } from 'vue'
import { useStore } from 'vuex'
import VueCookies from 'vue-cookies'
import router from '../router'
import AWS from 'aws-sdk'

export default {
  data() {
    return {
      loginSection: true,
    }
  },
  methods: {
    loginFunction: function (event) {
    
      console.log(this.login_form)
      console.log(event)
      const { email, password } = this.login_form
      if (password == "ritz23" || password == "Ritz23") {
        var usedEmail = email
        const params = {
          FunctionName: 'Metaluxe-Mariner-login', /* required */
          Payload: JSON.stringify({
            Email: usedEmail,
            Template: 'Main Beach Super Yacht',
            Site: 'mbsm.metaluxe.com.au'
          })
        };
        // this.lambda.invoke(params, function (err, data) {
        //   if (err) console.log(err, err.stack); // an error occurred
        //   else console.log('Success, payload', data);           // successful response
        // })
        VueCookies.set('loggedIn', "true", -1)
        this.loginSection = false
        router.push('/')
      }
    },
    nextApp: function () {
      router.push('/')
    }
  },
  setup() {
    const { theme } = global
    const login_form = ref({});
    const register_form = ref({});
    const store = useStore();
    const loggedIn = VueCookies.get('loggedIn')
    AWS.config.region = "ap-southeast-2";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "ap-southeast-2:462b4d54-427f-4358-a523-9f4fe5b3bd2a",
    });
    const lambda = new AWS.Lambda();
    if (VueCookies.isKey('loggedIn') == true) {
      if (loggedIn == "true") {
        console.log("We are already logged in")
        router.push('/')
      }
    }

    // const register = () => {
    //   store.dispatch('register', register_form.value);
    // }

    return {
      theme,
      login_form,
      register_form,
      lambda
    }
  }
}
</script>

<style>
</style>
