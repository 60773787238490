import { createRouter, createWebHistory } from 'vue-router'
import DashboardHome from '../views/DashboardHome.vue'
import DashboardHomeNew from '../views/DashboardHomeNew.vue'
import DefaultView from '../views/DefaultView.vue'
import Admin from '../views/Admin.vue'
import RegisterUser from '../views/RegisterUser.vue'
import Login from '../views/Login.vue'
import AssetType from '../views/AssetType.vue'
import AssetType1 from '../views/AssetType1.vue'

import AssetView from '../views/AssetView.vue'
import VideoView from '../views/VideoView.vue'
import RenderView from '../views/RenderView.vue'
import AssetViewDetail from '../views/AssetViewDetail.vue'
import AssetPdfViewDetail from '../views/AssetPdfViewDetail.vue'
import PdfViewDetail from '../views/PdfViewDetail.vue'
import AssetVideoViewDetail from '../views/AssetVideoViewDetail.vue'
import PanoramaView from '../views/PanoramaView.vue'
import FloorplanView from '../views/FloorplanView.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import TopLevelAssetView from '../views/TopLevelAssetView.vue'
import TopLevelPdfViewDetail from '../views/TopLevelPdfViewDetail.vue'
import TopLevelVideoViewDetail from '../views/TopLevelVideoViewDetail.vue'
import RitzCarltonAssetType from '../views/RitzCarltonAssetType.vue'
import RetailPiazzaAssetType from '../views/RetailPiazzaAssetType.vue'

import MainBeachSuperyachtMarinaAssetType from '../views/MainBeachSuperyachtMarinaAssetType.vue'

import PrecinctAssetType from '../views/PrecinctAssetType.vue'
import { auth } from '../firebase'
import VueCookies from 'vue-cookies'

const routes = [
  {
    path: '/',
    name: 'DashboardHome',
    component: DashboardHomeNew,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/default',
    name: 'DefaultView',
    component: DefaultView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    props: true,
    component: Admin,
  },
  {
    path: '/asset/:assetType1/:assetType',
    name: 'AssetType',
    props: true,
    component: AssetType,
  },
  {
    path: '/asset/:assetType',
    name: 'AssetType1',
    props: true,
    component: AssetType1,
  },
  {
    path: '/asset/:assetType1/:assetType/:viewType/:lid?/:collectionId?',
    name: 'AssetView',
    props: true,
    component: AssetView,
  },
  {
    path: '/toplevelasset/:assetType1/:viewType/:lid?/:collectionId?',
    name: 'TopLevelAssetView',
    props: true,
    component: TopLevelAssetView,
  },
  {
    path: '/retail-piazza',
    name: 'RetailPiazzaAssetType',
    props: true,
    component: RetailPiazzaAssetType,
  },
  {
    path: '/ritz-carlton',
    name: 'RitzCarltonAssetType',
    props: true,
    component: RitzCarltonAssetType,
  },
  {
    path: '/main-beach-superyacht-marina',
    name: 'MainBeachSuperyachtMarinaAssetType',
    props: true,
    component: MainBeachSuperyachtMarinaAssetType,
  },
  {
    path: '/precinct',
    name: 'PrecinctAssetType',
    props: true,
    component: PrecinctAssetType,
  },
  {
    path: '/asset/:assetType1/:assetType/:viewType/pdf',
    name: 'AssetPdfViewDetail',
    props: true,
    component: AssetPdfViewDetail,
  },
  {
    path: '/asset/view/video/:collectionId/:guid',
    name: 'VideoView',
    props: true,
    component: VideoView,
  },
  {
    path: '/asset/view/video/:collectionId/:guid',
    name: 'AssetVideoViewDetail',
    props: true,
    component: AssetVideoViewDetail,
  },
  {
    path: '/asset/:assetType1/:assetType/:viewType/view/:order',
    name: 'AssetViewDetail',
    props: true,
    component: AssetViewDetail,
  },
  {
    path: '/pdf/:assetType',
    name: 'TopLevelPdfViewDetail',
    props: true,
    component: TopLevelPdfViewDetail,
  },
  {
    path: '/video/:assetType',
    name: 'TopLevelVideoViewDetail',
    props: true,
    component: TopLevelVideoViewDetail,
  },
  {
    path: '/asset/:assetType/:viewType/view/:order',
    name: 'RenderView',
    props: true,
    component: RenderView,
  },
  {
    path: '/asset/:assetType/:viewType/view/:order',
    name: 'PanoramaView',
    props: true,
    component: PanoramaView,
  },
  {
    path: '/asset/:assetType/:viewType/view/:order',
    name: 'FloorplanView',
    props: true,
    component: FloorplanView,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterUser,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  next()
  return
})

export default router
